/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import url("//fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i");
@import "variables";

body.login {
	background: #3c3c3b;
	font-family: $baseFontFamily;
	h1 {
		a {
			width: 320px;
			height: 150px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	box-shadow: none;
}

.login #backtoblog,
.login #nav,
.login .privacy-policy-page-link {
	a {
		color: $color1;
		&:hover {
			color: $color2;
			text-decoration: underline;
		}
	}
}

.wp-core-ui {
	.button-primary {
		background: $color1;
		color: #FFF;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-shadow: none;
		text-transform: uppercase;
		font-weight: 700;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: $color2 !important;
			outline: 0;
			box-shadow: none !important;
		}
	}
}
